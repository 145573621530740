<template>
  <v-card>
    <v-card-title>
      <h4 class="text-h5 font-weight-regular">
        {{ action === "edit" ? "Update" : "Create" }} {{ itemClass.title }}
      </h4>
      <v-spacer />
      <v-btn @click="$emit('dismiss')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-form v-model="formIsValid" ref="itemForm" @submit.stop.prevent="handleSubmit">
      <v-card-text>
        <template v-if="itemClass.type === 'post-code'">
          <v-text-field v-model="postCode" :rules="nameRequiredRule" label="Post Code" required />
          <v-text-field v-model="division" :rules="nameRequiredRule" label="Division" required />
          <v-text-field
            v-model="divisionCode"
            :rules="nameRequiredRule"
            label="Division Code"
            required
          />
          <v-text-field v-model="district" :rules="nameRequiredRule" label="District" required />
          <v-text-field
            v-model="districtCode"
            :rules="nameRequiredRule"
            label="District Code"
            required
          />
          <v-text-field v-model="thana" :rules="nameRequiredRule" label="Thana" required />
          <v-text-field v-model="thanaCode" :rules="nameRequiredRule" label="Thana Code" required />
          <v-text-field v-model="subOffice" label="Sub-office" />
        </template>
        <template v-else-if="itemClass.type === 'profession'">
          <v-text-field v-model="category" :rules="nameRequiredRule" label="Category" required />
          <v-text-field
            v-model="subCategory"
            :rules="nameRequiredRule"
            label="Sub Category"
            required
          />
          <v-text-field v-model="score" :rules="requiredRules" label="Score" required />
          <v-text-field v-model="sectorCode" :rules="requiredRules" label="Sector Code" required />
        </template>
        <template v-else-if="itemClass.type === 'address'">
          <v-text-field v-model="name" :rules="nameRequiredRule" label="Name" required />

          <v-select
            v-model="areaType"
            :rules="requiredRules"
            :items="['Rural', 'Urban', 'NULL']"
            label="Area Type"
          />
          <v-text-field v-model="code" :rules="requiredRules" label="Code" required />
          <v-text-field v-model="parent" :rules="requiredRules" label="Parent" required />
          <v-text-field v-model="selfLevel" :rules="requiredRules" label="Self Level" required />
          <v-text-field v-model="status" :rules="requiredRules" label="Status" required />
        </template>
        <template v-else-if="itemClass.type === 'terms'">
          <v-text-field v-model="channel" readonly :rules="requiredRules" label="Channel" />
          <v-textarea
            v-model="terms"
            :rules="requiredRules"
            label="Terms and Conditions"
            rows="10"
            outlined
            required
            auto-grow
            style="font-size: 14px; font-family: monospace"
          />
        </template>
        <template v-else-if="itemClass.type === 'branch'">
          <v-text-field v-model="branch" :rules="requiredRules" label="Branch" />
          <v-text-field v-model="branchCode" :rules="requiredRules" label="Branch Code" />
          <v-text-field v-model="district" :rules="requiredRules" label="District" />
          <v-text-field v-model="division" :rules="requiredRules" label="Division" />
          <v-select
            v-model="bankingMode"
            :items="bankingModeOptions"
            :rules="requiredRules"
            label="Banking Mode"
          ></v-select>
        </template>
        <template v-else-if="itemClass.type === 'channel-text'">
          <v-select v-model="channel" :items="channelNames" label="Channel" />
          <v-text-field v-model="textId" :rules="requiredRules" label="Text ID" />
          <v-textarea v-model="text" :rules="requiredRules" label="Text" />
        </template>
        <template v-else-if="itemClass.type === 'account-nature'">
          <v-text-field v-model="name" :rules="requiredRules" label="Name" />
          <v-text-field v-model="code" :rules="requiredRules" label="Code" />
          <v-text-field
            v-model="businessSegmentCode"
            :rules="requiredRules"
            label="Business Segment Code"
          />
        </template>
        <template v-else-if="itemClass.type === 'residence-status'">
          <v-text-field v-model="name" :rules="requiredRules" label="Name" />
          <v-text-field v-model="code" :rules="requiredRules" label="Code" />
          <v-text-field
            v-model="customerCategory"
            :rules="requiredRules"
            label="Customer Category"
          />
        </template>
        <template v-else-if="itemClass.type === 'cbs-product'">
          <v-select
            v-model="bankingMode"
            :items="bankingModeOptions"
            :rules="requiredRules"
            label="Banking Mode"
          ></v-select>
          <v-text-field v-model="name" :rules="requiredRules" label="Name" />
          <v-text-field v-model="code" :rules="requiredRules" label="Code" />
          <v-text-field v-model="accountType" :rules="requiredRules" label="Account Type" />
          <v-text-field v-model="assisted" :rules="requiredRules" label="Assisted" />
          <v-textarea v-model="description" :rules="requiredRules" label="Description" />
        </template>
        <template
          v-else-if="
            [
              'place-of-birth',
              'nominee-relationship',
              'account-currency',
              'banking-mode',
              'income-source',
              'nationality',
            ].includes(itemClass.type)
          "
        >
          <v-text-field v-model="name" :rules="requiredRules" label="Name" />
        </template>
        <template
          v-else-if="
            ['service-type', 'country', 'location', 'gender', 'residence-status'].includes(
              itemClass.type
            )
          "
        >
          <v-text-field v-model="name" :rules="requiredRules" label="Name" />
          <v-text-field v-model="code" :rules="requiredRules" label="Code" />
        </template>
        <template v-else-if="itemClass.type === 'identity-doc'">
          <v-text-field v-model="name" :rules="requiredRules" label="Name" />
          <v-text-field v-model="code" :rules="requiredRules" label="Code" />
          <v-text-field
            type="number"
            min="1"
            max="2"
            step="1"
            v-model="imageCount"
            :rules="requiredRules"
            label="Image Count"
          />
        </template>
        <template v-else>
          <v-text-field v-model="name" :rules="nameRequiredRule" label="Name" required />
          <v-text-field v-model="score" :rules="requiredRules" label="Score" required />
        </template>
      </v-card-text>

      <v-divider />

      <v-card-actions class="px-5 py-2 lighten-4">
        <v-spacer />
        <v-btn type="submit" color="success" class="px-5">
          Submit <v-icon class="ml-2 mb-1">mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { REPO_ITEM_CREATE, REPO_ITEM_UPDATE } from "@/constants/api";

export default {
  name: "RepoItemForm",
  props: {
    action: {
      type: String,
      required: false,
      default: () => "edit",
    },
    item: {
      type: Object,
      required: false,
    },
    itemClass: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formIsValid: false,
      name: null,
      score: null,
      category: null,
      subCategory: null,
      areaType: null,
      code: null,
      parent: null,
      selfLevel: null,
      status: null,
      channel: null,
      terms: null,
      postCode: null,
      division: null,
      branch: null,
      branchCode: null,
      divisionCode: null,
      district: null,
      districtCode: null,
      thana: null,
      thanaCode: null,
      subOffice: null,
      customerCategory: null,
      businessSegmentCode: null,
      sectorCode: null,
      accountType: null,
      assisted: null,
      description: null,
      imageCount: 1,
      textId: null,
      text: null,
      bankingMode: null,
      requiredRules: [(v) => (v !== undefined && v !== null) || "This field is required"],
      nameRequiredRule: [(v) => !!v || "Name field is required"],
      imageCountRequiredRules: [
        (v) => (Number(v) && v >= 1 && v <= 2) || "The image count should be either 1 or 2",
      ],
    };
  },
  computed: {
    channelNames: () => ["MFS", "CBS"],
    bankingModeOptions: () => ["CONVENTIONAL", "ISLAMI"],
  },
  mounted() {
    if (this.action === "edit" && this.item) {
      if (this.itemClass.type === "post-code") {
        this.postCode = this.item.postCode;
        this.division = this.item.division;
        this.divisionCode = this.item.divisionCode;
        this.district = this.item.district;
        this.districtCode = this.item.districtCode;
        this.thana = this.item.thana;
        this.thanaCode = this.item.thanaCode;
        this.subOffice = this.item.subOffice;
      } else if (this.itemClass.type === "profession") {
        this.category = this.item.category;
        this.subCategory = this.item.subCategory;
        this.score = this.item.score;
        this.sectorCode = this.item.sectorCode;
      } else if (this.itemClass.type === "address") {
        this.name = this.item.name;
        this.areaType = this.item.areaType;
        this.code = this.item.code;
        this.parent = this.item.parent;
        this.selfLevel = this.item.selfLevel;
        this.status = this.item.status;
      } else if (this.itemClass.type === "branch") {
        this.name = this.item.name;
        this.branch = this.item.branch;
        this.branchCode = this.item.branchCode;
        this.district = this.item.district;
        this.division = this.item.division;
        this.bankingMode = this.item.bankingMode;
      } else if (this.itemClass.type === "channel-text") {
        this.textId = this.item.textId;
        this.channel = this.item.channel;
        this.text = this.item.text;
      } else {
        //console.log(this.item);
        Object.keys(this.item).forEach((el) => {
          if (el === "id") return;
          this[el] = this.item[el];
        });
      }
    }
  },
  methods: {
    setItemValue() {},
    getSubmitURL() {
      return (this.action === "edit" ? REPO_ITEM_UPDATE : REPO_ITEM_CREATE).replace(
        ":item:",
        this.itemClass.type
      );
    },
    handleSubmit() {
      this.$refs.itemForm.validate();
      if (!this.formIsValid) {
        return this.$iziToast.error({
          title: "Form is not valid",
        });
      }

      this.loading = true;
      let url = this.getSubmitURL();
      let submitData = {};

      if (this.itemClass.type === "post-code") {
        submitData = {
          postCode: this.postCode,
          division: this.division,
          divisionCode: this.divisionCode,
          district: this.district,
          districtCode: this.districtCode,
          thana: this.thana,
          thanaCode: this.thanaCode,
          subOffice: this.subOffice,
        };
      } else if (this.itemClass.type === "profession") {
        submitData = {
          category: this.category,
          subCategory: this.subCategory,
          score: this.score,
          sectorCode: this.sectorCode,
        };
      } else if (this.itemClass.type === "address") {
        submitData = {
          name: this.name,
          areaType: this.areaType,
          code: this.code,
          parent: this.parent,
          selfLevel: this.selfLevel,
          status: this.status,
        };
      } else if (this.itemClass.type === "branch") {
        submitData = {
          branch: this.branch,
          branchCode: this.branchCode,
          district: this.district,
          division: this.division,
          bankingMode: this.bankingMode,
        };
      } else if (this.itemClass.type === "account-nature") {
        submitData = {
          name: this.name,
          code: this.code,
          businessSegmentCode: this.businessSegmentCode,
        };
      } else if (this.itemClass.type === "cbs-product") {
        submitData = {
          name: this.name,
          code: this.code,
          accountType: this.accountType,
          assisted: this.assisted,
          // channel: this.channel, // can be overridden by server
          description: this.description,
          bankingMode: this.bankingMode,
        };
      } else if (this.itemClass.type === "residence-status") {
        submitData = {
          name: this.name,
          code: this.code,
          customerCategory: this.customerCategory,
        };
      } else if (this.itemClass.type === "channel-text") {
        submitData = {
          textId: this.textId,
          channel: this.channel,
          text: this.text,
        };
      } else if (this.itemClass.type === "identity-doc") {
        submitData = {
          name: this.name,
          code: this.code,
          imageCount: this.imageCount,
        };
      } else if (
        [
          "nationality",
          "nominee-relationship",
          "account-currency",
          "banking-mode",
          "income-source",
          "place-of-birth",
        ].includes(this.itemClass.type)
      ) {
        submitData = {
          name: this.name,
        };
      } else if (["service-type", "country", "location", "gender"].includes(this.itemClass.type)) {
        submitData = {
          name: this.name,
          code: this.code,
        };
      } else {
        submitData = {
          name: this.name,
          score: this.score,
        };
      }

      if (this.action === "edit") {
        submitData.id = this.item.id;
      }

      this.$axios
        .post(url, submitData)
        .then((response) => {
          if (response.data.code === 0) {
            this.$emit("updated");
            this.$iziToast.success({ title: "Success" });
          } else {
            this.$iziToast.showError({ response });
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          if (err.response?.data?.code === 1100) {
            this.$emit("updated");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
